import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { customerOnboarding, getToken } from "services/authServices";
import { JwtTokenValidate, copyToClipboard, formatDate, handleErrorMessage, setLocalStorageData } from "lib/utils";
import styles from "./CustomerList.module.scss";
import { Switch, Tooltip } from "@mui/material";
import LoadingScreen from "component/LoadingScreen";
import { AUTH_TYPE, TOAST_TYPES } from "enum/enums";
import { hasCustomAddOnFlag, JWT_TOKEN, REACT_APP_FITVIEW_URL, ROUTES } from "lib/Constants";
import CustomButton from "atoms/customButton/CustomButton";
import NoDataPlaceHolder from "component/NoDataPlaceHolder";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { setHasCustomAddOn, setProfileInfo } from "store/auth/reducer";
import { HANDLE_AUTH } from "store/auth/action.types";
import { addToast } from "store/toaster/reducer";
import { CUSTOMER_LIST } from "store/customer/action.types";
import { setCustomerSearchList, setListLoading } from "store/customer/reducer";
import CustomDialog from "component/CustomDialog";
import Register from "component/Register";
import { CopyAll, Edit } from "@mui/icons-material";

const CustomerList = () => {
	const { isInternalUser, userDetails, loading } = useSelector((store: RootState) => store.auth);
	const { customerList, customerSearchList, listLoading } = useSelector((store: RootState) => store.customer);
	const navigate = useNavigate();
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);
	const [searchStore, setSearchStore] = useState<string>("");
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState<boolean>(false);
    const [storeUrl,setStoreUrl] = useState<string>("");
	const [preDetails, setPreDetails] = useState<{email:string,isTrialDemo:boolean,storeName:string}>({email:"",isTrialDemo:true,storeName:""});
	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setSearchStore(value);
		if (value) {
			const filteredList = customerList?.filter((el: Record<string, string>) => el.name.toLowerCase().includes(value.toLowerCase()));
			dispatch(setCustomerSearchList(filteredList));
		} else {
			dispatch(setCustomerSearchList(customerList));
		}
	};

	const getStoreToken = async (storeUrl: string) => {
		setButtonLoading(true);
		try {
			const res = await getToken(storeUrl);
			const decodedToken = JwtTokenValidate(res?.data?.token);
			dispatch(setProfileInfo(decodedToken?.properties));
			setLocalStorageData(JWT_TOKEN, res?.data?.token);
			setLocalStorageData(hasCustomAddOnFlag, decodedToken?.properties?.hasCustomAddon);
			dispatch(setHasCustomAddOn(decodedToken?.properties?.hasCustomAddon));
			dispatch({ type: HANDLE_AUTH, payload: AUTH_TYPE.LOGIN });
		} catch (error) {
			console.log(error);
			dispatch(addToast({ type: TOAST_TYPES.ERROR, messsage: handleErrorMessage(error) }));
		} finally {
			setButtonLoading(false);
		}
	};

	const handleCustomerOnboard = async (store_url: string, onboarded: boolean) => {
		dispatch(setListLoading(true));
		try {
			await customerOnboarding(store_url, onboarded);
			dispatch({ type: CUSTOMER_LIST });
		} catch (error) {
			dispatch(setListLoading(false));
			dispatch(addToast({ type: TOAST_TYPES.ERROR, messsage: handleErrorMessage(error) }));
		}
	};

	const handleShowModal = (url="",data:{email:string,isTrialDemo:boolean,storeName:string} = {email:"",isTrialDemo:true,storeName:""}) => {	
		if(typeof url === "string")	{
			setStoreUrl(url);
		}
		setPreDetails(data)
		setShowModal(!showModal);
	};

	useEffect(() => {
		if (!isInternalUser && !loading) {
			navigate(ROUTES.HOME);
		} else if (isInternalUser && !loading) {
			dispatch({ type: CUSTOMER_LIST });
		}
	}, [isInternalUser, loading, dispatch]);

	return listLoading || loading ? (
		<div className="flex-wrap align-center justify-center w-full">
			<LoadingScreen />
		</div>
	) : (
		<div className={` ${styles.list} flex flex-column justify-between`}>
			{customerList?.length > 0 ? (
				<>
					<div className={` ${styles.list__form} flex-wrap gap-10 justify-between`}>
						<h2>
							<span>SWAN</span> Store
						</h2>
						<div className={` ${styles.list__formBtn} flex gap-10 align-center`}>
							<input placeholder="Search store.." type="text" value={searchStore} onChange={handleOnChange} />
							<CustomButton buttonText="Register" className="button" handleFunc={handleShowModal} />
						</div>
					</div>{" "}
					<div className={` ${styles.list__outer}`}>
						<div className={` ${styles.list__wrap} flex`}>
							{customerSearchList?.length > 0 ? (
								customerSearchList?.map((el: Record<string, any>, index) => (
									<div className={` ${styles.list__item} `} key={index}>
										<aside className={el?.storeUrl === userDetails?.store_url ? styles.active : ""}>
											<h2>
												<span role={el?.appInstalled ? "button" : "none"} className={`${el?.appInstalled ? "" : styles.isDisabled}`} onClick={() => {
													getStoreToken(el?.storeUrl);
												}}>{el?.name}</span>
                                                {el?.hasCustomAddon && <span className={` ${styles.list__editLink}`} role="button" onClick={()=>handleShowModal(el?.storeUrl,{storeName:el?.name,email:userDetails?.email,isTrialDemo:el?.isTrialDemo})}><Edit/></span>}
											</h2>
											
											<div className={`flex align-center ${styles.listSwitch}`}>
												{(el?.onbardedAt || !el?.onbardedAt) && el?.appInstalled && (
													<Switch
														checked={el?.onbardedAt ? true : false}
														onChange={(e) => {
															e.stopPropagation();
															handleCustomerOnboard(el?.storeUrl, e.target.checked);
														}}
													/>
												)}
												{el?.onbardedAt && formatDate(el?.onbardedAt)}
											</div>
											<span className={` ${styles.storeUrl}`}>{el?.storeUrl}</span>
											<div className="flex gap-10">
												{!el?.appInstalled && <p className={` ${styles.appNotInstalled}`}>App not installed</p>}
												{el?.isTrialDemo &&  <p className={` ${styles.appNotInstalled} ${styles.green}`}>Demo</p>}
											</div>
											{el?.isTrialDemo && el?.hasCustomAddon  &&  (
												<Tooltip title={`${REACT_APP_FITVIEW_URL}/custom/${el?.storeUrl}`} placement="bottom-start">
													
														<p className={` ${styles.appNotInstalled} ${styles.blue}`}>
															<CopyAll />
															<span id={`copy-store-url${el?.storeUrl}`} onClick={() => copyToClipboard(`${REACT_APP_FITVIEW_URL}/custom/${el?.storeUrl}`, `copy-store-url${el?.storeUrl}`, "Copied", "Copy store url")}>
																Copy store url
															</span>
														</p>
													
												</Tooltip>
											)}
										</aside>
									</div>
								))
							) : (
								<div className="flex-wrap align-center justify-center w-full">
									<NoDataPlaceHolder message="No store found in this list" />
								</div>
							)}
						</div>
						<div className={`${styles.list__button} flex-wrap justify-end`}>
							<CustomButton buttonText={"Next"} className="button" handleFunc={() => navigate(ROUTES.HOME)} disabled={buttonLoading || !userDetails?.store_url} loading={buttonLoading} />
						</div>
					</div>
				</>
			) : (
				<div className="flex-wrap align-center justify-center w-full">
					<NoDataPlaceHolder message="No store found in this list" />
				</div>
			)}
			<CustomDialog className="registerModal disabled-overlay" open={showModal} handleShowModal={handleShowModal}>
				<Register handleShowModal={handleShowModal} showClose storeUrl={storeUrl} preDetails={preDetails} />
			</CustomDialog>
		</div>
	);
};

export default CustomerList;
