/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {  useMemo, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Select, ListSubheader, TextField, InputAdornment, MenuItem } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import { registerUser } from "services/authServices";
import { getCode, getNames } from "country-list";
import Input from "atoms/input/Input";
import CustomButton from "atoms/customButton/CustomButton";
import { TOAST_TYPES } from "enum/enums";
import { checkStoreUrl, copyToClipboard, handleErrorMessage, isValidEmail } from "lib/utils";
import { useDispatch } from "react-redux";
import { addToast } from "store/toaster/reducer";
import { RegiterFormData } from "interface/interface";
import { customerInternal } from "services/authServices";
import { Close, CopyAllOutlined } from "@mui/icons-material";
import { CUSTOMER_LIST } from "store/customer/action.types";

interface SignupProps {
  setRegisterSuccess?: (a: boolean) => void;
  handleShowModal?:()=>void,
  showClose?:boolean;
  storeUrl?:string;
  preDetails?:{email:string,isTrialDemo:boolean,storeName:string};
}

const allOptions = getNames();
const Register = ({ setRegisterSuccess,handleShowModal,showClose,storeUrl,preDetails }: SignupProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState(allOptions?.[0]);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const [showUrl, setShowUrl] = useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<RegiterFormData>({
    defaultValues: {
      name: preDetails?.storeName??"",
      email:preDetails?.email?? "",
      storeUrl: storeUrl??"",
      emailsTier1: "",
      emailsTier2:"",
      isTrialDemo:preDetails?.isTrialDemo??true
    },
  });
  const displayedOptions = useMemo(() => allOptions.filter((option) => option.toLowerCase().includes(searchText.toLowerCase())), [searchText]);

  const onSubmit: SubmitHandler<RegiterFormData> = async (data) => {
    
    const { email, name, storeUrl,emailsTier1,emailsTier2,isTrialDemo } = data;
    if (!isValidEmail(email.trim())) {
      setError("email", { type: "custom", message: "Please enter a valid email" });
    } 
    if (emailsTier1 && !isValidEmail(emailsTier1.trim())) {
      setError("emailsTier1", { type: "custom", message: "Please enter a valid email" });
    } 
    if (emailsTier2 && !isValidEmail(emailsTier2.trim())) {
      setError("emailsTier2", { type: "custom", message: "Please enter a valid email" });
    } 
    else if (!name.trim()) {
      setError("name", { type: "random" });
    } else if (!storeUrl.trim()) {
      setError("storeUrl", { type: "random" });
    } else if (!checkStoreUrl(storeUrl)) {
      setError("storeUrl", { type: "custom", message: "Please enter a valid store url" });
    }
     else {
      setLoading(true);
      try {
        const dataRep = { email,name,storeUrl,isTrialDemo,location: getCode(selectedOption) ?? "", ...(emailsTier1 ? { emailsTier1 } : {}),...(emailsTier2 ? { emailsTier2 } : {}) };
        const reqestType = storeUrl ? "patch" : "post";
        const res = await customerInternal(dataRep,reqestType)
        setShowUrl(res?.data?.customStoreUrl);
        setRegisterSuccess?.(true);
      } catch (error: any) {
        dispatch(addToast({type:TOAST_TYPES.ERROR, message:handleErrorMessage(error)}));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRehreshAndCLose = () => {
     if (showUrl) {
      dispatch({ type: CUSTOMER_LIST });
     }
    handleShowModal?.()
     setShowUrl("")
  }

  return (
    <>
       { showClose && <div  className={`flex pointer justify-end ${loading && "disabled"}`}>
					<span  role={loading ? "none" :"button" } onClick={handleRehreshAndCLose}>
						<Close />
					</span>
				</div>}
   
    <div className={` auth signUpMain register`}>
        
     { showUrl ? <div>
        <h2>
          Share this link with your customer
        </h2>
        <div className="copyBox">
        <p>{showUrl}</p> 
         <button className="button" onClick={()=>copyToClipboard(showUrl,"copy-button-url","copied","copy")}><CopyAllOutlined/><span id="copy-button-url">copy</span></button>
        {/* <CustomButton buttonIcon={} buttonText="Copy" id="copy-button-url" handleFunc={()=>copyToClipboard(showUrl,"copy-button-url")}/> */}
            </div>
        <CustomButton buttonText="Done" handleFunc={handleRehreshAndCLose}/>
     </div>  :<form onSubmit={handleSubmit(onSubmit)}>
        <h2>{preDetails?.storeName || "Register"}</h2>
        {/* <h5>Your store not connected with us</h5> */}
        <p>Please enter the details below</p>
        <div className="signUpForm">
        <Input
          placeholder="Enter your email"
          type="text"
          label="Email"
          register={register}
          inputName="email"
          errorMessage={errors.email ? (errors.email.message ? errors.email.message : "This Field is required") : ""}
        />
          <Input
          placeholder="Enter your email tier 1"
          type="text"
          label="Email Tier 1"
          required={false}
          register={register}
          inputName="emailsTier1"
          errorMessage={errors.emailsTier1 ? (errors.emailsTier1.message ? errors.emailsTier1.message : "This Field is required") : ""}
        />
          <Input
          placeholder="Enter your tier 2"
          type="text"
          label="Email Tier 2"
          required={false}
          register={register}
          inputName="emailsTier2"
          errorMessage={errors.emailsTier2 ? (errors.emailsTier2.message ? errors.emailsTier2.message : "This Field is required") : ""}
        />
          <Input
          type="checkbox"
          label="For trial demo"
          required={false}
          register={register}
          inputName="isTrialDemo"
        />
        <Input
          placeholder="Enter store Name"
          type="text"
          label="Store name"
          register={register}
          inputName="name"
          errorMessage={errors.name && "This Field is required "}
        />
        <Input
          placeholder="e.g. swan.myshopify.com"
          type="text"
          label="Store url"
          register={register}
          inputName="storeUrl"
          readOnly={storeUrl?true:false}
          errorMessage={errors.storeUrl ? (errors.storeUrl.message ? errors.storeUrl.message : "This Field is required") : ""}
        />
        <div className="customSelect">
          <label>Location</label>
          <Select
            MenuProps={{ autoFocus: false }}
            fullWidth
            labelId="search-select-label"
            id="search-select"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            onClose={() => setSearchText("")}
            renderValue={() => selectedOption}
          >
            <ListSubheader>
              <TextField
                size="small"
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayedOptions?.map((option, i) => (
              <MenuItem key={i} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>
        </div>
        <CustomButton type="submit" buttonText={storeUrl?"Update":"Register"} className="button rounded full" loading={loading} disabled={loading} />
      </form>}
    
    </div>
    </>
  );
};

export default Register;
